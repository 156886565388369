import { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  Button,
  Paper,
  ListItemIcon,
  Zoom,
  ListItemText,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Snackbar
} from '@mui/material';
import { SportsEsports } from '@mui/icons-material';
import Confetti from 'react-confetti';
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { Context } from '../GlobalState';
import {
  createGame,
  addPlayerToGame,
  findPlayerInGame
} from '../modules/game-play';
import { useNavigate, useParams } from 'react-router-dom';

const Results = () => {
  const [players, setPlayers] = useState();
  const [allPlayersInRoom, setAllPlayersInRoom] = useState();
  const [playerIdInGame, setPlayerIdInGame] = useState();
  const [gameInfo, setGameInfo] = useState();
  const [winners, setWinners] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [newGameId, setNewGameId] = useState();
  const { playerId } = useContext(Context);
  const { roomId, gameId } = useParams();
  const navigate = useNavigate();

  // const getBackgroundColour = defaultColour => {
  //   console.log(defaultColour, winners);
  //   if (winners) {
  //     if (winners.length === 1) {
  //       return winners[0].colour;
  //     } else {
  //       return `linear-gradient(${winners
  //         .map(winner => winner.colour)
  //         .join(', ')})`;
  //     }
  //   } else {
  //     return player ? player.colour : defaultColour;
  //   }
  // };

  // calculate the winners and scores
  useEffect(() => {
    const getPlayers = async () => {
      const snapshot = await getDocs(
        collection(
          getFirestore(),
          'signification',
          roomId,
          'games',
          gameId,
          'players'
        )
      );

      const foundPlayers = {};
      for (const playerSnap of snapshot.docs) {
        const { answers } = playerSnap.data();

        let totalScore = 0;
        for (const personId of Object.keys(answers)) {
          if (answers[personId] === personId) {
            totalScore++;
            if (personId === 'bot') {
              totalScore += 1;
            }
          }
        }

        foundPlayers[playerSnap.id] = { ...playerSnap.data(), totalScore };
      }

      const topScore = Object.keys(foundPlayers).length + 2; // 1 point for guessing each player, and 2 for the Squeege
      const topPlayers = Object.keys(foundPlayers).filter(
        playerId => foundPlayers[playerId].totalScore === topScore
      );

      const player = await findPlayerInGame(roomId, gameId, playerId);
      setPlayerIdInGame(player.id);
      setWinners(topPlayers);
      foundPlayers['bot'] = {
        name: 'Squeege',
        definition: gameInfo.definition
      };
      setPlayers(foundPlayers);
    };

    if (playerId && gameInfo) {
      getPlayers();
    }
  }, [gameId, roomId, playerId, gameInfo]);

  useEffect(() => {
    let unsubscribe;
    const watchForNewGame = async () => {
      unsubscribe = onSnapshot(
        query(
          collection(getFirestore(), 'signification', roomId, 'games'),
          where('state', '!=', 'completed')
        ),

        async gamesSnap => {
          setNewGameId(
            gamesSnap.docs.length > 0 ? gamesSnap.docs[0].id : undefined
          );
        }
      );
    };
    watchForNewGame();

    return () => unsubscribe();
  }, [roomId]);

  // get the game data to display the answers
  useEffect(() => {
    const getGameData = async () => {
      const gameData = (
        await getDoc(
          doc(getFirestore(), 'signification', roomId, 'games', gameId)
        )
      ).data();
      setGameInfo(gameData);
    };
    getGameData();
  }, [gameId, roomId]);

  // fetch stats on all players in the room
  useEffect(() => {
    let unsubscribe;
    const watchAllPlayersInRoom = async () => {
      unsubscribe = onSnapshot(
        collection(getFirestore(), 'signification', roomId, 'players'),
        async snapshot => {
          const foundPlayers = [];
          for (const playerSnap of snapshot.docs) {
            const { name, colour, stats } = playerSnap.data();
            if (stats) {
              foundPlayers.push({
                name,
                colour,
                stats,
                id: playerSnap.id,
                percentage: Math.round((stats.won / stats.played) * 100)
              });
            }
          }
          setAllPlayersInRoom(foundPlayers);
        }
      );
    };

    watchAllPlayersInRoom();

    return () => {
      unsubscribe();
    };
  }, [gameId, roomId]);

  if (
    !players ||
    !gameInfo ||
    !winners ||
    !playerIdInGame ||
    !allPlayersInRoom
  ) {
    return <LinearProgress />;
  }

  return (
    <>
      {winners && winners.some(winnerId => winnerId === playerIdInGame) && (
        <Confetti colors={winners.map(winnerId => players[winnerId].colour)} />
      )}
      <Paper sx={{ p: 3, my: 3 }}>
        <Stack spacing={3}>
          {winners.length > 0 && (
            <Box>
              {winners.length > 1 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    And the winners are
                  </Typography>
                  <Typography variant="h3">
                    {winners
                      .map(winnerId => `${players[winnerId].name}`)
                      .join(', ')}
                  </Typography>
                </>
              )}
              {winners.length === 1 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    And the winner is
                  </Typography>
                  <Typography variant="h3">
                    {players[winners[0]].name}
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Box>
            <Typography variant="h5">Scores</Typography>
            <List>
              {Object.keys(players)
                .filter(playerId => playerId !== 'bot')
                .map(playerId => (
                  <Zoom in key={playerId}>
                    <ListItem>
                      <ListItemIcon sx={{ color: players[playerId].colour }}>
                        <SportsEsports />
                      </ListItemIcon>
                      <ListItemText
                        primary={players[playerId].name}
                        secondary={`Points: ${players[playerId].totalScore}`}
                      />
                    </ListItem>
                  </Zoom>
                ))}
            </List>
          </Box>
          <Divider />
          <Box>
            <Typography variant="h5">
              Your answers for "{gameInfo.word}"
            </Typography>
            {Object.keys(players).map(id => (
              <Paper key={id} sx={{ p: 3, my: 3 }}>
                <Typography variant="body1">
                  "{players[id].definition}"
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Correct answer</TableCell>
                      <TableCell>Your answer</TableCell>
                      <TableCell>Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{players[id].name}</TableCell>
                      <TableCell>
                        {players[players[playerIdInGame].answers[id]].name}
                      </TableCell>
                      <TableCell>
                        {id === 'bot'
                          ? players[playerIdInGame].answers[id] === id
                            ? 2
                            : 0
                          : players[playerIdInGame].answers[id] === id
                          ? 1
                          : 0}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            ))}
            <Typography variant="body1" align="center">
              Total score: {players[playerIdInGame].totalScore}
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography variant="h5">Running stats</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell>Games played</TableCell>
                  <TableCell>Games won</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPlayersInRoom
                  .sort((p1, p2) => (p1.percentage < p2.percentage ? 1 : -1))
                  .map(player => (
                    <TableRow key={player.id}>
                      <TableCell>{player.name}</TableCell>
                      <TableCell>{player.stats.played}</TableCell>
                      <TableCell>
                        {player.stats.won} ({player.percentage}%)
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              let brandNewGameId;
              if (!newGameId) {
                setSnackbarMessage('Creating new game...');
                brandNewGameId = await createGame(roomId);
              } else {
                setSnackbarMessage('Joining new game...');
                brandNewGameId = newGameId;
              }
              await addPlayerToGame(roomId, brandNewGameId, playerId);
              navigate(`/room/${roomId}/game/${brandNewGameId}`, {
                replace: true
              });
            }}
          >
            {newGameId ? 'Join new game' : 'Create new game'}
          </Button>
          <Button
            fullWidth
            onClick={() => navigate(`/room/${roomId}`, { replace: true })}
          >
            Return to lobby
          </Button>
        </Stack>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(snackbarMessage)}
        message={snackbarMessage}
      />
    </>
  );
};

export default Results;
