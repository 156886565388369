import { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  Button,
  Zoom,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Paper,
  Divider,
  Snackbar
} from '@mui/material';
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  where
} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { SportsEsports, SmartToy } from '@mui/icons-material';
import { Player } from '@lottiefiles/react-lottie-player';
import readingBook from '../lottie/reading-book.json';
import { Context } from '../GlobalState';
import {
  createGame,
  addPlayerToGame,
  findPlayerInGame
} from '../modules/game-play';

const RoomLobby = () => {
  const [players, setPlayers] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [gameId, setGameId] = useState();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { playerId } = useContext(Context);

  // watch for players entering the room to simply display them
  useEffect(() => {
    let unsubscribe;

    const watchForPlayers = async () => {
      unsubscribe = onSnapshot(
        collection(getFirestore(), 'signification', roomId, 'players'),
        async snapshot => {
          const foundPlayers = [];
          for (const playerSnap of snapshot.docs) {
            const { name, colour } = playerSnap.data();
            foundPlayers.push({ name, colour, id: playerSnap.id });
          }
          setPlayers(foundPlayers);
        }
      );
    };

    watchForPlayers();

    return () => {
      unsubscribe();
    };
  }, [roomId]);

  // watch if anyone in the room creates a game, so we can update the UI to offer to join it
  useEffect(() => {
    let unsubGames;

    const watchForGames = async () => {
      unsubGames = onSnapshot(
        query(
          collection(getFirestore(), 'signification', roomId, 'games'),
          where('state', '==', 'created')
        ),
        async docsSnapshot => {
          if (docsSnapshot.docs.length > 0) {
            const gameId = docsSnapshot.docs[0].id;
            setGameId(gameId);
          } else {
            setGameId(undefined);
          }
        }
      );
    };

    watchForGames();

    return () => {
      unsubGames();
    };
  }, [roomId]);

  if (!players) {
    return <LinearProgress />;
  }

  let actionChoice;
  if (!gameId) {
    actionChoice = (
      <>
        <Button
          size="large"
          disabled={players.length < 2}
          variant="contained"
          onClick={async () => {
            setSnackbarMessage('Creating a new game...');
            const gameId = await createGame(roomId);
            await addPlayerToGame(roomId, gameId, playerId);
            navigate(`/room/${roomId}/game/${gameId}`, { replace: true });
          }}
        >
          Create new game
        </Button>
        {players.length < 2 && (
          <Typography variant="caption" textAlign="center">
            At least two human players required to create a new game.
          </Typography>
        )}
      </>
    );
  } else {
    actionChoice = (
      <Button
        size="large"
        variant="contained"
        onClick={async () => {
          setSnackbarMessage('Joining the game...');
          const playerInGame = await findPlayerInGame(roomId, gameId, playerId);
          if (!Boolean(playerInGame)) {
            await addPlayerToGame(roomId, gameId, playerId);
          }
          navigate(`/room/${roomId}/game/${gameId}`, { replace: true });
        }}
      >
        Join game
      </Button>
    );
  }

  return (
    <Stack>
      <Player
        autoplay
        loop
        src={readingBook}
        style={{ height: '300px', width: '300px' }}
      />
      <Paper sx={{ p: 3, mb: 3 }}>
        <Stack spacing={3}>
          <Box>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontStyle: 'italic' }}
            >
              Players in the room...
            </Typography>
            <List>
              {players.map(player => (
                <Zoom in key={player.id}>
                  <ListItem>
                    <ListItemIcon sx={{ color: player.colour }}>
                      <SportsEsports />
                    </ListItemIcon>
                    <ListItemText primary={player.name} />
                  </ListItem>
                </Zoom>
              ))}
              <ListItem>
                <ListItemIcon>
                  <SmartToy />
                </ListItemIcon>
                <ListItemText
                  primary={'Squeege'}
                  secondary="The mischevious bot you'll be up against :)"
                />
              </ListItem>
            </List>
          </Box>
          {actionChoice}
          <Divider />
          <Button
            size="small"
            color="error"
            variant="text"
            onClick={async () => {
              navigate('/', { replace: true });
            }}
          >
            Leave room
          </Button>
        </Stack>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(snackbarMessage)}
        message={snackbarMessage}
      />
    </Stack>
  );
};

export default RoomLobby;
