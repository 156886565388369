import { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  Button,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Paper,
  Divider
} from '@mui/material';
import { SportsEsports, SmartToy, ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getFirestore,
  onSnapshot,
  collection,
  updateDoc,
  doc,
  Timestamp
} from 'firebase/firestore';
import { Player } from '@lottiefiles/react-lottie-player';
import pacman from '../lottie/pacman.json';
import { Context } from '../GlobalState';
import { cancelGame, removePlayerFromGame } from '../modules/game-play';

const GameLobby = () => {
  const [players, setPlayers] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { playerId } = useContext(Context);
  const navigate = useNavigate();
  const { roomId, gameId } = useParams();

  // watch for the players that have joined this game to display them
  useEffect(() => {
    let unsubscribe;

    const watchForPlayers = async () => {
      unsubscribe = onSnapshot(
        collection(
          getFirestore(),
          'signification',
          roomId,
          'games',
          gameId,
          'players'
        ),
        async snapshot => {
          const foundPlayers = [];
          for (const playerSnap of snapshot.docs) {
            const { name, colour } = playerSnap.data();
            foundPlayers.push({ name, colour, id: playerSnap.id });
          }
          setPlayers(foundPlayers);
        }
      );
    };

    watchForPlayers();

    return () => {
      unsubscribe();
    };
  }, [roomId, gameId]);

  // watch the game state, so we know which component to render
  useEffect(() => {
    let unsubGame;

    const watchGame = async () => {
      unsubGame = onSnapshot(
        doc(getFirestore(), 'signification', roomId, 'games', gameId),
        async docSnapshot => {
          // check for a cancelled game
          if (docSnapshot.exists()) {
            const gameState = docSnapshot.data().state;
            if (gameState === 'begin') {
              navigate(`/room/${roomId}/game/${gameId}/get-definition`, {
                replace: true
              });
            }
          }
        }
      );
    };

    watchGame();

    return () => {
      unsubGame();
    };
  }, [gameId, navigate, roomId]);

  if (!players) {
    return <LinearProgress />;
  }

  let exitOption;
  if (players.length === 1) {
    exitOption = (
      <Button
        color="error"
        onClick={async () => {
          await cancelGame(roomId, gameId);
          navigate(`/room/${roomId}`, { replace: true });
        }}
      >
        Cancel game
      </Button>
    );
  } else {
    exitOption = (
      <Button
        startIcon={<ArrowBack />}
        onClick={async () => {
          await removePlayerFromGame(roomId, gameId, playerId);
          navigate(`/room/${roomId}`, { replace: true });
        }}
      >
        Back to room
      </Button>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Player autoplay loop src={pacman} style={{ height: '333px' }} />
      <Stack spacing={3}>
        <Box>
          <Typography variant="body1" gutterBottom sx={{ fontStyle: 'italic' }}>
            Players ready to play!
          </Typography>
          <List>
            {players.map(player => (
              <Zoom in key={player.id}>
                <ListItem>
                  <ListItemIcon sx={{ color: player.colour }}>
                    <SportsEsports />
                  </ListItemIcon>
                  <ListItemText primary={player.name} />
                </ListItem>
              </Zoom>
            ))}
            <ListItem>
              <ListItemIcon>
                <SmartToy />
              </ListItemIcon>
              <ListItemText primary="Squeege" />
            </ListItem>
          </List>
        </Box>
        <Button
          size="large"
          disabled={players.length < 2}
          variant="contained"
          onClick={() => setShowConfirmation(true)}
        >
          Begin game
        </Button>
        {players.length < 2 && (
          <Typography variant="caption" textAlign="center">
            Minimum 2 human players required to begin game.
          </Typography>
        )}
        <Divider />
        {exitOption}
      </Stack>
      <Dialog open={showConfirmation}>
        <DialogTitle>Ready, set?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to start the game with these {players.length}{' '}
            players and the Squeege?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateDoc(
                doc(getFirestore(), 'signification', roomId, 'games', gameId),
                {
                  state: 'begin',
                  gameStarted: Timestamp.now()
                }
              );
            }}
          >
            Yes!
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default GameLobby;
