import { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Stack,
  Paper,
  Box,
  Slide,
  FormControl,
  Button,
  Select,
  MenuItem,
  Zoom,
  LinearProgress
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getFirestore,
  getDoc,
  doc,
  getDocs,
  collection
} from 'firebase/firestore';
import { Player } from '@lottiefiles/react-lottie-player';
import swinging from '../lottie/swing-under-ufo.json';
import { Context } from '../GlobalState';
import { shuffleArray } from '../modules/utils';
import { addAnswersFromPlayer } from '../modules/game-play';

const DecisionTime = () => {
  const [word, setWord] = useState();
  const [allDefinitions, setAllDefinitions] = useState();
  const [players, setPlayers] = useState();
  const [answers, setAnswers] = useState();
  const { roomId, gameId } = useParams();
  const navigate = useNavigate();
  const { playerId } = useContext(Context);

  // setup the UI for the possible answers
  useEffect(() => {
    const getDefinitions = async () => {
      const { definition, word } = (
        await getDoc(
          doc(getFirestore(), 'signification', roomId, 'games', gameId)
        )
      ).data();
      setWord(word);

      const playersSnapshot = await getDocs(
        collection(
          getFirestore(),
          'signification',
          roomId,
          'games',
          gameId,
          'players'
        )
      );
      const playerDefinitions = [];
      const players = [];
      const answers = {};
      for (const playerSnap of playersSnapshot.docs) {
        const { definition, name, colour } = playerSnap.data();
        playerDefinitions.push({ definition, id: playerSnap.id });
        players.push({ name, id: playerSnap.id, colour });
        answers[playerSnap.id] = '';
      }
      setAllDefinitions(
        shuffleArray([...playerDefinitions, { id: 'bot', definition }])
      );
      setPlayers(shuffleArray([...players, { id: 'bot', name: 'Squeege' }]));
      answers.bot = '';
      setAnswers(answers);
    };

    getDefinitions();
  }, [gameId, roomId]);

  if (!allDefinitions || !word) {
    return <LinearProgress />;
  }

  return (
    <Stack sx={{ my: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Box>
            <Slide in direction="right">
              <Typography variant="h4" gutterBottom>
                Decision time!
              </Typography>
            </Slide>
            <Zoom in>
              <Typography
                variant="h1"
                sx={{ pl: 1, fontSize: 44, wordWrap: 'break-word' }}
              >
                {word}
              </Typography>
            </Zoom>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ fontStyle: 'italic' }}>
            Who wrote which definition?
          </Typography>
          {allDefinitions.map(defn => (
            <Box key={defn.id}>
              <Typography gutterBottom>{defn.definition}</Typography>
              <FormControl fullWidth>
                <Select
                  value={answers[defn.id]}
                  onChange={evt =>
                    setAnswers({ ...answers, [defn.id]: evt.target.value })
                  }
                >
                  {players.map(player => (
                    <MenuItem value={player.id} key={player.id}>
                      {player.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ))}
          <Button
            variant="contained"
            fullWidth
            disabled={
              !Object.keys(answers).every(
                playerId => answers[playerId].length > 0
              )
            }
            size="large"
            onClick={async () => {
              await addAnswersFromPlayer(roomId, gameId, playerId, answers);
              navigate(`/room/${roomId}/game/${gameId}/waiting/answers`, {
                replace: true
              });
            }}
          >
            Submit answers
          </Button>
        </Stack>
      </Paper>
      <Player
        autoplay
        loop
        src={swinging}
        style={{ height: '300px', width: '300px' }}
      />
    </Stack>
  );
};

export default DecisionTime;
