import { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  Button,
  Box,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Snackbar
} from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { Player } from '@lottiefiles/react-lottie-player';
import { getFirestore, onSnapshot, collection } from 'firebase/firestore';
import { Context } from '../GlobalState';
import { addPlayerToRoom } from '../modules/game-play';
import profileCards from '../lottie/profile-cards.json';
import { useNavigate, useParams } from 'react-router-dom';

const colours = [
  'red',
  'orange',
  'yellow',
  'green',
  'pink',
  'blue',
  'indigo',
  'violet',
  'white'
];

const PlayerSetup = () => {
  const [selectedColour, setSelectedColour] = useState();
  const [name, setName] = useState('');
  const [takenColours, setTakenColours] = useState([]);
  const [initialisingPlayer, setInitialisingPlayer] = useState(false);
  const { setPlayerId } = useContext(Context);
  const navigate = useNavigate();
  let { roomId } = useParams();

  useEffect(() => {
    const playerId = localStorage.getItem(`roomId-${roomId}`);
    if (playerId) {
      navigate(`/room/${roomId}`, { replace: true });
    }
  }, [navigate, roomId]);

  // monitor all players in the room so we can see what colours have been taken
  useEffect(() => {
    let unsubscribe;

    const watchForPlayers = async () => {
      unsubscribe = onSnapshot(
        collection(getFirestore(), 'signification', roomId, 'players'),
        async snapshot => {
          const foundPlayers = [];
          for (const playerSnap of snapshot.docs) {
            const { colour } = playerSnap.data();
            foundPlayers.push(colour);
          }
          setTakenColours(foundPlayers);
        }
      );
    };

    watchForPlayers();

    return () => {
      unsubscribe();
    };
  }, [roomId]);

  // read the player's previous entry from localstorage to pre-populate the inputs
  useEffect(() => {
    const checkLocalPlayer = async () => {
      const playerStr = localStorage.getItem('player');
      if (playerStr) {
        const playerSetup = JSON.parse(playerStr);
        setName(playerSetup.name);
        if (!takenColours.includes(playerSetup.colour)) {
          setSelectedColour(playerSetup.colour);
        }
      }
    };

    if (takenColours) {
      checkLocalPlayer();
    }
  }, [takenColours]);

  return (
    <Stack spacing={5} sx={{ mb: 8 }}>
      <Player
        autoplay
        src={profileCards}
        style={{ height: '300px', width: '300px' }}
      />
      <Box>
        <Typography variant="h5" gutterBottom>
          What's your name?
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Skeletor"
          value={name}
          fullWidth
          onChange={evt => setName(evt.target.value)}
        />
      </Box>
      <Box>
        <Typography variant="h5">Choose your colour!</Typography>
        <ImageList sx={{ width: '100%', height: 333 }} cols={3} rowHeight={99}>
          {colours.map(colour => (
            <ImageListItem key={colour}>
              <Box backgroundColor={colour} height="100%" width="100%"></Box>
              <ImageListItemBar
                title={colour}
                position="top"
                actionIcon={
                  <IconButton
                    onClick={() => setSelectedColour(colour)}
                    disabled={takenColours.includes(colour)}
                  >
                    {colour === selectedColour ||
                    takenColours.includes(colour) ? (
                      <CheckBox />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Button
        variant="contained"
        disabled={
          !Boolean(name) || !Boolean(selectedColour) || initialisingPlayer
        }
        fullWidth
        size="large"
        onClick={async () => {
          setInitialisingPlayer(true);
          const playerId = await addPlayerToRoom(roomId, name, selectedColour);
          // storing player's general name and colour preference for a new room
          localStorage.setItem(
            'player',
            JSON.stringify({ name, colour: selectedColour })
          );

          // storing if they've ever been in this room, so we can skip profile setup
          localStorage.setItem(`roomId-${roomId}`, playerId);
          setPlayerId(playerId);
          navigate(`/room/${roomId}`, { replace: true });
        }}
      >
        Create profile
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={initialisingPlayer}
        message="Positioning satellites..."
      />
    </Stack>
  );
};

export default PlayerSetup;
