// waiting for people to choose which definition belongs to which person
import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  Zoom,
  ListItemText,
  ListItemIcon,
  LinearProgress
} from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { getFirestore, onSnapshot, collection, doc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const WaitingForAnswers = () => {
  const [players, setPlayers] = useState();
  const { gameId, roomId } = useParams();
  const navigate = useNavigate();

  // watch for which players have answered
  useEffect(() => {
    let unsubscribe;

    const watchForPlayers = async () => {
      unsubscribe = onSnapshot(
        collection(
          getFirestore(),
          'signification',
          roomId,
          'games',
          gameId,
          'players'
        ),
        async snapshot => {
          const foundPlayers = [];
          for (const playerSnap of snapshot.docs) {
            const { name, colour, answers } = playerSnap.data();
            foundPlayers.push({ name, colour, answers, id: playerSnap.id });
          }
          setPlayers(foundPlayers);
        }
      );
    };

    watchForPlayers();

    return () => {
      unsubscribe();
    };
  }, [gameId, roomId]);

  // if the game state changes to all answers submitted, go to the results page
  useEffect(() => {
    let unsubGame;

    const watchGame = async () => {
      unsubGame = onSnapshot(
        doc(getFirestore(), 'signification', roomId, 'games', gameId),
        async docSnapshot => {
          const gameState = docSnapshot.data().state;
          if (gameState === 'completed') {
            navigate(`/room/${roomId}/game/${gameId}/results`, {
              replace: true
            });
          }
        }
      );
    };

    watchGame();

    return () => {
      unsubGame();
    };
  }, [gameId, navigate, roomId]);

  if (!players) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Typography variant="body1" gutterBottom sx={{ fontStyle: 'italic' }}>
        Players with submitted answers...
      </Typography>
      <List>
        {players.map(player => (
          <Zoom in key={player.id}>
            <ListItem>
              <ListItemIcon sx={{ color: player.colour }}>
                {player.answers ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </ListItemIcon>
              <ListItemText primary={player.name} />
            </ListItem>
          </Zoom>
        ))}
      </List>
    </Box>
  );
};

export default WaitingForAnswers;
