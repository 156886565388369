import { Stack, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import pigeon from '../lottie/waiting-pigeon.json';
import kitty from '../lottie/kitty-lightbulb.json';
import spaceman from '../lottie/spaceman.json';
import sloth from '../lottie/happy-sloth.json';
import banana from '../lottie/banana.json';
import ufo from '../lottie/alien-abduction-on-tape.json';
import guitarist from '../lottie/guitarist.json';
import ideas from '../lottie/waiting-for-ideas.json';
import sheep from '../lottie/sheep-on-computer.json';

const Waiting = () => {
  const lottieAnimations = [
    pigeon,
    kitty,
    spaceman,
    sloth,
    banana,
    ufo,
    guitarist,
    ideas,
    sheep
  ];
  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <Stack spacing={8}>
        <Player
          autoplay
          loop
          src={
            lottieAnimations[
              Math.floor(Math.random() * lottieAnimations.length)
            ]
          }
          sx={{ height: '300px', width: '300px' }}
        />
        <Outlet />
      </Stack>
    </Paper>
  );
};

export default Waiting;
