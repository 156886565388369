import { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Stack,
  Paper,
  Button,
  TextField,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { SportsEsports } from '@mui/icons-material';
import { createRoom, findRoom } from '../modules/game-play';
import welcomeBot from '../lottie/welcome-bot.json';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import Footer from './Footer';

const RoomChoice = () => {
  const [choiceState, setChoiceState] = useState('home');
  const [roomName, setRoomName] = useState();
  const [memberships, setMemberships] = useState();
  const [error, setError] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    const checkMemberships = async () => {
      const roomMemberships = [];
      const items = { ...localStorage };
      for (const key of Object.keys(items)) {
        if (key.startsWith('roomId-')) {
          const roomId = key.split('roomId-')[1];
          const snap = await getDoc(
            doc(getFirestore(), 'signification', roomId, 'players', items[key])
          );
          if (snap.exists()) {
            const { name, colour } = snap.data();
            const roomSnap = await getDoc(
              doc(getFirestore(), 'signification', roomId)
            );
            roomMemberships.push({
              name,
              colour,
              roomName: roomSnap.data().roomName,
              roomId
            });
          }
        }
      }
      setMemberships(roomMemberships);
    };

    checkMemberships();
  }, []);

  if (!memberships) {
    return <LinearProgress />;
  }

  let content;
  if (choiceState === 'home') {
    content = (
      <>
        <Typography variant="h3" sx={{ fontFamily: 'Grape Nuts' }}>
          Welcome to Signification!
        </Typography>
        {memberships.length > 0 && (
          <>
            <Typography variant="h6">
              Rooms you're already a member of
            </Typography>
            <List dense>
              {memberships.map(membership => (
                <ListItem
                  secondaryAction={
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(`/room/${membership.roomId}/player-setup`)
                      }
                    >
                      Enter
                    </Button>
                  }
                >
                  <ListItemIcon sx={{ color: membership.colour }}>
                    <SportsEsports />
                  </ListItemIcon>
                  <ListItemText
                    primary={membership.roomName}
                    secondary={membership.name}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        <Typography variant="h6">Been invited to join a room?</Typography>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => setChoiceState('joinRoom')}
        >
          Join a room
        </Button>
        <Typography variant="h6">Or start your own room!</Typography>
        <Stack spacing={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setChoiceState('createRoom')}
          >
            Create a room
          </Button>
        </Stack>
      </>
    );
  } else if (choiceState === 'createRoom') {
    content = (
      <>
        <Typography variant="h6" gutterBottom>
          What would you like to name your room?
        </Typography>
        <TextField
          variant="outlined"
          autoFocus
          label="Room name"
          placeholder="Reality Zone"
          fullWidth
          error={Boolean(error)}
          helperText={error}
          onChange={evt => setRoomName(evt.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            const existingRoomId = await findRoom(roomName);
            if (existingRoomId) {
              setError('Sorry, this room name already exists');
            } else {
              const roomId = await createRoom(roomName.trim());
              navigate(`/room/${roomId}/player-setup`);
            }
          }}
        >
          Create room
        </Button>
        <Button
          size="small"
          onClick={() => {
            setChoiceState('home');
            setError(undefined);
          }}
        >
          Go back
        </Button>
      </>
    );
  } else if (choiceState === 'joinRoom') {
    content = (
      <>
        <Typography variant="h5" gutterBottom>
          What's the name of the room?
        </Typography>
        <TextField
          variant="outlined"
          label="Room name"
          autoFocus
          placeholder="Steve's Den"
          fullWidth
          error={Boolean(error)}
          helperText={error}
          onChange={evt => setRoomName(evt.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            const roomId = await findRoom(roomName.trim());
            if (roomId) {
              navigate(`/room/${roomId}/player-setup`);
            } else {
              setError("Sorry, this room wasn't found");
            }
          }}
        >
          Join room
        </Button>
        <Button
          size="small"
          onClick={() => {
            setChoiceState('home');
            setError(undefined);
          }}
        >
          Go back
        </Button>
      </>
    );
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ mb: 8 }}>
        <Player
          autoplay
          loop
          src={welcomeBot}
          style={{ height: '300px', width: '300px' }}
        />
        <Paper sx={{ p: 3 }}>
          <Stack spacing={5}>{content}</Stack>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default RoomChoice;
