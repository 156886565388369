import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
  AppBar,
  Container,
  Box,
  Toolbar,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { Context } from '../GlobalState';
import { scrollToTop } from '../modules/utils';
import Footer from './Footer';
import { getDoc, doc, getFirestore } from 'firebase/firestore';

const RoomContainer = () => {
  const [roomName, setRoomName] = useState();
  const [player, setPlayer] = useState();
  const [explainSignification, setExplainSignification] = useState(false);
  const { playerId, setPlayerId } = useContext(Context);
  let location = useLocation();
  let { roomId } = useParams();

  // curious which path we are on
  useEffect(() => {
    console.log('location', location);
    scrollToTop();
  }, [location]);

  /* 
    If the user refreshed the page, we should still be able to get their playerId
    from localStorage
  */
  useEffect(() => {
    const player = localStorage.getItem(`roomId-${roomId}`);
    if (player) {
      setPlayerId(player);
    }
  }, [roomId, setPlayerId]);

  // when we have a roomId, get the room name to display in the appbar
  useEffect(() => {
    const getRoomName = async () => {
      const docSnapshot = await getDoc(
        doc(getFirestore(), 'signification', roomId)
      );
      const { roomName } = docSnapshot.data();
      setRoomName(roomName);
    };

    getRoomName();
  }, [roomId]);

  // when we have a playerId, get the player's name
  useEffect(() => {
    const getPlayer = async () => {
      const docSnapshot = await getDoc(
        doc(getFirestore(), 'signification', roomId, 'players', playerId)
      );
      setPlayer(docSnapshot.data());
    };

    if (playerId) {
      getPlayer();
    }
  }, [playerId, roomId]);

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography
            variant="h4"
            sx={{ flexGrow: 1, fontFamily: 'Grape Nuts' }}
          >
            Signification
          </Typography>
          <Stack alignItems="center">
            {player && <Typography variant="body1">{player.name}</Typography>}
            <Typography variant="caption">{roomName}</Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: '100vh',
          background: player ? player.colour : 'transparent',
          px: 1
        }}
      >
        <Container maxWidth="sm">
          <Outlet />
        </Container>
      </Box>
      <Footer />
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => setExplainSignification(false)}
        open={explainSignification}
      >
        <DialogTitle>What is Signification?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Signification is a hilarious game where players guess outrageous and
            sometimes obscene definitions, then try to match who guessed them!
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RoomContainer;
