import { Typography, AppBar, Toolbar, Button } from '@mui/material';

const Footer = () => (
  <AppBar position="relative" color="primary" sx={{ top: 'auto', bottom: 0 }}>
    <Toolbar>
      <Typography variant="caption">
        Copyright © {new Date().getFullYear()}
      </Typography>
      <Button size="small" href="https://syzygy.lol" color="secondary">
        Syzygy LOL
      </Button>
    </Toolbar>
  </AppBar>
);

export default Footer;
