import {
  collection,
  getDocs,
  Timestamp,
  addDoc,
  getFirestore,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
  updateDoc
} from 'firebase/firestore';

const getRandomWordWithDefinition = async () => {
  let firstResponse;
  do {
    const res = await fetch('https://api.urbandictionary.com/v0/random');
    const data = await res.json();

    firstResponse = data.list[0];
  } while (
    firstResponse.thumbs_down >= firstResponse.thumbs_up ||
    firstResponse.definition.length > 222
  );

  return {
    word: firstResponse.word,
    definition: firstResponse.definition.replaceAll(/\[|\]/g, ''),
    rating: {
      thumbsDown: firstResponse.thumbs_down,
      thumbsUp: firstResponse.thumbs_up
    },
    firstResponse
  };
};

const createRoom = async roomName => {
  const docRef = await addDoc(collection(getFirestore(), 'signification'), {
    createdAt: Timestamp.now(),
    roomName
  });
  return docRef.id;
};

const findRoom = async roomName => {
  const querySnap = await getDocs(
    query(
      collection(getFirestore(), 'signification'),
      where('roomName', '==', roomName)
    )
  );

  if (querySnap.docs.length > 0) {
    return querySnap.docs[0].id;
  } else {
    return null;
  }
};

const createGame = async roomId => {
  const { word, definition, rating, firstResponse } =
    await getRandomWordWithDefinition();
  const docRef = await addDoc(
    collection(getFirestore(), 'signification', roomId, 'games'),
    {
      gameCreated: Timestamp.now(),
      word,
      definition,
      rating,
      firstResponse,
      state: 'created'
    }
  );
  return docRef.id;
};

const addPlayerToRoom = async (roomId, name, colour) => {
  const docRef = await addDoc(
    collection(getFirestore(), 'signification', roomId, 'players'),
    {
      createdAt: Timestamp.now(),
      name,
      colour
    }
  );

  return docRef.id;
};

const addDefinitionFromPlayer = async (
  roomId,
  gameId,
  playerId,
  definition
) => {
  const playerSnap = await findPlayerInGame(roomId, gameId, playerId);
  await updateDoc(playerSnap.ref, { definition });
};

const addAnswersFromPlayer = async (roomId, gameId, playerId, answers) => {
  const playerSnap = await findPlayerInGame(roomId, gameId, playerId);
  await updateDoc(playerSnap.ref, { answers });
};

const removePlayerFromRoom = async (roomId, playerId) => {
  await deleteDoc(
    doc(getFirestore(), 'signification', roomId, 'players', playerId)
  );
};

const cancelGame = async (roomId, gameId) => {
  await deleteDoc(
    doc(getFirestore(), 'signification', roomId, 'games', gameId)
  );
};

const findPlayerInGame = async (roomId, gameId, playerId) => {
  const playerInGameSnap = await getDocs(
    query(
      collection(
        getFirestore(),
        'signification',
        roomId,
        'games',
        gameId,
        'players'
      ),
      where('playerId', '==', playerId)
    )
  );

  return playerInGameSnap.docs[0];
};

const removePlayerFromGame = async (roomId, gameId, playerId) => {
  const playerSnap = await findPlayerInGame(roomId, gameId, playerId);
  await deleteDoc(playerSnap.ref);
};

const addPlayerToGame = async (roomId, gameId, playerId) => {
  const playerSnap = await getDoc(
    doc(getFirestore(), 'signification', roomId, 'players', playerId)
  );
  const playerInGame = await addDoc(
    collection(
      getFirestore(),
      'signification',
      roomId,
      'games',
      gameId,
      'players'
    ),
    {
      addedAt: Timestamp.now(),
      name: playerSnap.data().name,
      colour: playerSnap.data().colour,
      playerId: playerSnap.id
    }
  );

  return playerInGame.id;
};

// const lookForGameInRoom = async roomId => {
//   const gamesSnap = await getDocs(
//     query(
//       collection(getFirestore(), 'signification', roomId, 'games'),
//       where('state', '!=', 'completed')
//     )
//   );

//   return gamesSnap.docs.length > 0 ? gamesSnap.docs[0].id : null;
// };

export {
  createRoom,
  createGame,
  addPlayerToRoom,
  addPlayerToGame,
  findRoom,
  removePlayerFromRoom,
  removePlayerFromGame,
  cancelGame,
  // lookForGameInRoom,
  addDefinitionFromPlayer,
  addAnswersFromPlayer,
  findPlayerInGame
};
