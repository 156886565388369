const scrollToTop = () => window.scrollTo(0, 0);

const shuffleArray = array =>
  array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

const randomNumber = max => Math.floor(Math.random() * max);

export { scrollToTop, shuffleArray, randomNumber };
