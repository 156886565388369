import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const GameContainer = () => (
  <Box sx={{ py: 2 }}>
    <Outlet />
  </Box>
);

export default GameContainer;
