import { useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/grape-nuts';
import '@fontsource/roboto-flex';
import PlayerSetup from './components/PlayerSetup';
import RoomContainer from './components/RoomContainer';
import RoomLobby from './components/RoomLobby';
import GameLobby from './components/GameLobby';
import CaptureDefinition from './components/CaptureDefinition';
import Waiting from './components/Waiting';
import WaitingForDefinitions from './components/WaitingForDefinitions';
import WaitingForAnswers from './components/WaitingForAnswers';
import DecisionTime from './components/DecisionTime';
import Results from './components/Results';
import RoomChoice from './components/RoomChoice';
import Landing from './components/Landing';
import GameContainer from './components/GameContainer';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const baseTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light'
        }
      }),
    [prefersDarkMode]
  );

  const theme = createTheme(baseTheme, {
    typography: {
      fontFamily: 'Roboto Flex'
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="room/choice" element={<RoomChoice />} />
          <Route path="room/:roomId" element={<RoomContainer />}>
            <Route index element={<RoomLobby />} />
            <Route path="player-setup" element={<PlayerSetup />} />
            <Route path="game/:gameId" element={<GameContainer />}>
              <Route index element={<GameLobby />} />
              <Route path="get-definition" element={<CaptureDefinition />} />
              <Route path="decision-time" element={<DecisionTime />} />
              <Route path="waiting" element={<Waiting />}>
                <Route path="definitions" element={<WaitingForDefinitions />} />
                <Route path="answers" element={<WaitingForAnswers />} />
              </Route>
              <Route path="results" element={<Results />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
