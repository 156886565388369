import { useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  Zoom,
  Container,
  Alert,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc
} from 'firebase/firestore';
import { Player } from '@lottiefiles/react-lottie-player';
import chatbot from '../lottie/welcome-bot.json';
import { randomNumber } from '../modules/utils';

const Landing = () => {
  let navigate = useNavigate();
  const [confirmedAwareness, setConfirmedAwareness] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [definitions, setDefinitions] = useState();
  const [gameInfo, setGameInfo] = useState();

  // check for localstorage if they've already accepted the warning
  useEffect(() => {
    const checkLocalStorage = async () => {
      const aware = localStorage.getItem('awareOfGameContent');
      if (aware) {
        navigate('/room/choice', { replace: true });
      }
    };

    checkLocalStorage();
  }, [navigate]);

  const getRandomGame = async () => {
    let game;
    let defns = [];
    while (!game || defns.length === 0) {
      const roomsSnap = await getDocs(
        collection(getFirestore(), 'signification')
      );
      const randomRoomId =
        roomsSnap.docs[randomNumber(roomsSnap.docs.length)].id;
      const gamesSnap = await getDocs(
        collection(getFirestore(), 'signification', randomRoomId, 'games')
      );
      if (gamesSnap.docs.length > 0) {
        const randomGameId =
          gamesSnap.docs[randomNumber(gamesSnap.docs.length)].id;
        const randomGameRef = await getDoc(
          doc(
            getFirestore(),
            'signification',
            randomRoomId,
            'games',
            randomGameId
          )
        );

        game = randomGameRef.data();

        const defnDocs = await getDocs(
          collection(
            getFirestore(),
            'signification',
            randomRoomId,
            'games',
            randomGameId,
            'players'
          )
        );
        for (const defnSnap of defnDocs.docs) {
          defns.push(defnSnap.data().definition);
        }
      }
    }
    setGameInfo(game);
    setDefinitions(defns);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 3, mb: 8 }}>
      <Stack spacing={8} alignItems="center">
        <Typography variant="h3" sx={{ fontFamily: 'Grape Nuts' }}>
          Signification
        </Typography>
        <Player
          autoplay
          loop
          src={chatbot}
          style={{ height: '300px', width: '300px' }}
        />
        <Alert
          severity="warning"
          action={
            <Button
              size="small"
              onClick={async () => {
                await getRandomGame();
                setShowExample(true);
              }}
            >
              view example
            </Button>
          }
        >
          This game may contain obscene language
        </Alert>
        {showExample && (
          <Zoom in>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {gameInfo.word}
              </Typography>
              {definitions.map((defn, i) => (
                <Typography
                  key={i}
                  variant="body1"
                  gutterBottom
                  sx={{ fontStyle: 'italic' }}
                >
                  - {defn}
                </Typography>
              ))}
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                - {gameInfo.definition}
              </Typography>
            </Paper>
          </Zoom>
        )}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmedAwareness}
                onChange={() => setConfirmedAwareness(!confirmedAwareness)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="I am aware this game may contain obscene language"
            sx={{ mb: 3 }}
          />
          <Button
            variant="contained"
            disabled={!confirmedAwareness}
            fullWidth
            onClick={() => {
              localStorage.setItem('awareOfGameContent', 'true');
              navigate('/room/choice', { replace: true });
            }}
          >
            Begin
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default Landing;
