import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ContextProvider } from './GlobalState';
import './modules/firebase';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ContextProvider>
    <App game="Signification" />
  </ContextProvider>
  // </React.StrictMode>
);
