// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyABBPz7EovfzKoSBX9_ARNzkWJNtOq0u3Y',
  authDomain: 'syzygy-lol.firebaseapp.com',
  projectId: 'syzygy-lol',
  storageBucket: 'syzygy-lol.appspot.com',
  messagingSenderId: '725496127832',
  appId: '1:725496127832:web:c435655d620199bf9e052d',
  measurementId: 'G-4E991Q7F25'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
