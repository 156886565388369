import { useState, createContext } from 'react';

/*
  This code was inspired from
  https://www.geeksforgeeks.org/how-to-share-state-across-react-components-with-context/
*/

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [playerId, setPlayerId] = useState();
  // const [playerIdInGame, setPlayerIdInGame] = useState();
  // const [playerState, setPlayerState] = useState('chilling');
  // const [gameState, setGameState] = useState();
  // const [gameId, setGameId] = useState();
  // const [roomId, setRoomId] = useState();
  // const gameName = 'Signification';

  return (
    <Context.Provider
      value={{
        // roomId,
        // setRoomId,
        playerId,
        setPlayerId
        // gameId,
        // setGameId,
        // gameName,
        // playerState,
        // setPlayerState,
        // gameState,
        // setGameState,
        // playerIdInGame,
        // setPlayerIdInGame
      }}
    >
      {children}
    </Context.Provider>
  );
};
