import { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  Zoom,
  Button,
  Box,
  Paper,
  TextField,
  LinearProgress
} from '@mui/material';
import { getFirestore, getDoc, doc } from 'firebase/firestore';

import { Context } from '../GlobalState';
import { addDefinitionFromPlayer } from '../modules/game-play';
import { useNavigate, useParams } from 'react-router-dom';

const CaptureDefinition = () => {
  const [definition, setDefinition] = useState();
  const [word, setWord] = useState();
  const { playerId } = useContext(Context);
  const navigate = useNavigate();
  let { roomId, gameId } = useParams();

  // get the word to define for this game round
  useEffect(() => {
    const getWord = async () => {
      const gameWord = (
        await getDoc(
          doc(getFirestore(), 'signification', roomId, 'games', gameId)
        )
      ).data().word;
      setWord(gameWord);
    };
    getWord();
  }, [roomId, gameId]);

  if (!word) {
    return <LinearProgress />;
  }

  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <Stack spacing={8}>
        <Box>
          <Typography variant="h5" gutterBottom>
            What is the definition of
          </Typography>
          <Zoom in>
            <Typography
              variant="h1"
              sx={{ pl: 1, fontSize: 44, wordWrap: 'break-word' }}
            >
              {word}
            </Typography>
          </Zoom>
        </Box>

        <TextField
          variant="outlined"
          multiline
          label="Your definition"
          rows={8}
          fullWidth
          onChange={evt => setDefinition(evt.target.value)}
        />
        <Button
          variant="contained"
          disabled={!Boolean(definition)}
          fullWidth
          size="large"
          onClick={async () => {
            await addDefinitionFromPlayer(roomId, gameId, playerId, definition);
            navigate(`/room/${roomId}/game/${gameId}/waiting/definitions`, {
              replace: true
            });
          }}
        >
          Submit
        </Button>
      </Stack>
    </Paper>
  );
};

export default CaptureDefinition;
